import {
  FormControl,
  FormHelperText,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import validator from "email-validator";
import telefone from "telefone/parse";
import { useRef, useState } from "react";
import axios from "axios";
import Config from "../Config";
function subscribe(uid, fields, setState, localState, setLocalState) {
  const errorFields = {};
  // setLocalState("processing");
  //setState("loading");
  if (
    !fields.name.current.querySelector("#name").value ||
    fields.name.current.querySelector("#name").value === ""
  ) {
    errorFields.name = true;
  }
  if (
    !fields.email.current.querySelector("#email").value ||
    fields.email.current.querySelector("#email").value === "" ||
    validator.validate(fields.email.current.value)
  ) {
    errorFields.email = true;
  }
  if (
    !fields.phone.current.querySelector("#phone").value ||
    fields.phone.current.querySelector("#phone").value === "" ||
    telefone(fields.phone.current.value)
  ) {
    errorFields.phone = true;
  }
  if (
    !fields.plate.current.querySelector("#plate").value ||
    fields.plate.current.querySelector("#plate").value === ""
  ) {
    errorFields.plate = true;
  }
  setLocalState({ errorFields });
  if (Object.keys(errorFields).length === 0) {
    setLocalState({ errorFields: {}, status: "processing" });
    axios.post(`${Config().url}/subscribe/${Config().promoId}/${uid}`, { 
      email: fields.email.current.querySelector("#email").value,
      name: fields.name.current.querySelector("#name").value,
      plate: fields.plate.current.querySelector("#plate").value,
      phone: fields.phone.current.querySelector("#phone").value
    }).then((result) => {
       if (typeof result.data.error === 'undefined') {
        setState(result.data.status)
       } else {
        setLocalState({ ...localState, isOutOfArea: true })
        setState('not_subscribed')
       }
    }).catch((error) => { console.error(error)})
  }
}
function SubscribeForm(prop) {
  const fields = {
    name: useRef(),
    email: useRef(),
    phone: useRef(),
    plate: useRef(),
  };
  const [localState, setLocalState] = useState({
    errorFields: {},
    isOutOfArea: false,
    status: "initial",
  });
  return (
    <div>
      <FormControl
        style={{
          margin: "10px",
          backgroundColor: "#F2F2F2",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <FormHelperText id="name-helper-text">
          Insira seu nome completo
        </FormHelperText>
        <TextField
          required
          error={
            typeof localState.errorFields.name !== "undefined" ||
            localState.status === "processing"
              ? true
              : false
          }
          disabled={localState.status === "processing" ? true : false}
          id="name"
          name="name"
          aria-describedby="name-helper-text"
          variant="filled"
          ref={fields.name}
        />
        <FormHelperText id="email-helper-text">
          E-mail válido, utilizado no DriveSocial
        </FormHelperText>
        <TextField
          required
          error={
            typeof localState.errorFields.email !== "undefined" ? true : false
          }
          id="email"
          name="email"
          value={prop.profile.email}
          disabled={
            typeof prop.profile.email !== "undefined" ||
            localState.status === "processing"
              ? true
              : false
          }
          aria-describedby="email-helper-text"
          variant="filled"
          ref={fields.email}
        />
        <FormHelperText id="phone-helper-text">
          Insira um telefone válido
        </FormHelperText>
        <TextField
          required
          error={
            typeof localState.errorFields.phone !== "undefined" ? true : false
          }
          disabled={
            typeof prop.profile.phone !== "undefined" ||
            localState.status === "processing"
              ? true
              : false
          }
          value={prop.profile.phone}
          id="phone"
          name="phone"
          aria-describedby="phone-helper-text"
          variant="filled"
          ref={fields.phone}
        />
        <FormHelperText id="plate-helper-text">
          Placa do seu veículo
        </FormHelperText>
        <TextField
          required
          error={
            typeof localState.errorFields.plate !== "undefined" ? true : false
          }
          disabled={localState.status === "processing" ? true : false}
          id="plate"
          name="plate"
          aria-describedby="plate-helper-text"
          variant="filled"
          ref={fields.plate}
        />
        {localState.isOutOfArea && <Typography variant='body2' style={{color: 'red'}}>Desculpe, esta promoção não está disponível em sua região.</Typography> }
        <FormHelperText id="button-helper-text">
          Ao clicar no botão, os dados aqui inseridos serão compartilhados com a
          APROVEC, que poderá entrar em contato para oferecer ofertas de
          produtos.
        </FormHelperText>
        <Button
          disabled={localState.status === "processing" ? true : false}
          variant="contained"
          aria-describedby="button-helper-text"
          onClick={() => {
            subscribe(prop.profile.uid, fields, prop.setState, localState, setLocalState);
          }}
        >
          EU QUERO PARTICIPAR!
        </Button>
      </FormControl>
    </div>
  );
}

export default SubscribeForm;
