import {
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useRef, useState } from "react";
import Config from "../Config";

function EmailForm(prop) {
  const email = useRef();
  const [state, emailState] = useState("init");
  return (
    <FormControl
      style={{
        margin: "10px",
        backgroundColor: "#F2F2F2",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <FormHelperText id="name-helper-text">
        Para continuar, informe o e-mail de sua conta no DriveSocial
      </FormHelperText>
      <TextField
        style={{ margin: "10px" }}
        required
        id="email"
        name="email"
        aria-describedby="name-helper-text"
        variant="filled"
        ref={email}
      />
      {state === "invalid_account" && (
        <>
          <Typography variant="body2" style={{ color: "red" }}>
            E-mail não encontrado na base de usuários do DriveSocial. Corrija o
            e-mail e tente novamente.
          </Typography>
        </>
      ) || state === 'error' && (<Typography variant="body2" style={{ color: "red" }}>
      Ocorreu um erro. Tente novamente mais tarde.
    </Typography>)}
      <Button
        style={{ margin: "10px" }}
        variant="contained"
        onClick={() => {
            emailState('init')
          prop.setState("loading");
          const inputEmail = email.current.querySelector("#email").value;
          axios.get(`${Config().url}/session/${inputEmail}`).then((result) => {
            if (typeof result.data.session !== "undefined") {
              prop.setSessionKey(result.data.session)
              prop.getSubscription(prop.setState, result.data.session)
            } else if (typeof result.data.error !== "undefined") {
              emailState(result.data.error);
              prop.setState("initial_state");
            }
          }).catch((error) => {
            console.error(error)
            emailState('error')
          })
        }}
      >
        Continuar
      </Button>
    </FormControl>
  );
}

export default EmailForm;
