import { createTheme } from "@mui/system";

const theme = createTheme({
    palette: {
        primary: {
            main: 'white'
        },
        secondary: {
            main: 'black'
        }
    }
})
export default theme;