import {
  CircularProgress,
  Grow,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./App.css";
import Config from "./Config";
import folder from "./img/folder.png";
import caixaTerminca from './img/churrasqueira.png';
import SubscribeForm from "./SubscribeForm";
import theme from "./theme";
import Subscribed from "./Subscribed";
import EmailForm from "./EmailForm/EmailForm";

let params = new URLSearchParams(window.location.search);

let profile;

function App() {
  const [state, setState] = useState("initial_state");
  const [sessionKey, setSessionKey] = useState(null)
  if (sessionKey == null && params.get('sessionKey') != null) {
    setSessionKey(params.get('sessionKey'))
  }
  console.log(state)
  return (
    <div className="App">
      <Carousel autoFocus={true} swipeable={true}>
        <SubscribeFormPage state={state} setState={setState} setSessionKey={setSessionKey} sessionKey={sessionKey}/>
        <StartPage />
        <FrontPage />
      </Carousel>
    </div>
  );
}
function LoadingPage() {
  return (
    <div
      style={{
        margin: "10px",
        backgroundColor: "#F2F2F2",
        padding: "10px",
        borderRadius: "10px",
      }}
    >
      <CircularProgress />
    </div>
  );
}
function FrontPage() {
  return (
    <div style={{ width: "100%", height: "100%", verticalAlign: "middle" }}>
      <img src={folder} className="folder" />
    </div>
  );
}
function StartPage() {
  return (
    <div style={{ width: "100%", height: "100%", verticalAlign: "middle" }}>
      <img src={caixaTerminca} className="folder" />
    </div>
  );
}
function getSubscription(setState, sessionKey) {
  setTimeout(() => {
    getProfile(sessionKey).then((result) => {
      setState("loading");
      axios
        .get(
          `${Config().url}/subscription/${Config().promoId}/${profile.uid}`
        )
        .then((subscription) => {
          setState(subscription.data.status);
        });
    });
  }, 500);
}
function getProfile(sessionKey) {
  return new Promise((resolve, reject) => {
    window.history.pushState("", "", "/");

    setTimeout(() => {
      if (typeof profile === "undefined") {
        axios
          .get(`${Config().url}/profile/${sessionKey}`)
          .then((result) => {
            profile = result.data;
            resolve(profile);
          });
      } else {
        resolve(profile);
      }
    }, 500);
  });
}
function SubscribeFormPage(prop) {
  const component = () => {
    console.log(prop.sessionKey)
    if (typeof prop.sessionKey !== "undefined" && prop.sessionKey != null) {
      console.log("show complete")
      switch (prop.state) {
        case "initial_state":
          getSubscription(prop.setState, prop.sessionKey);
          return <LoadingPage />;
        case "loading":
          return <LoadingPage />;
        case "not_subscribed":
          return <SubscribeForm setState={prop.setState} profile={profile}  />;
        case "subscribed":
          return (
            <Subscribed
              profile={profile}
              sessionKey={prop.sessionKey}
            />
          );
        case "processing":
          getSubscription(prop.setState, prop.sessionKey);
          return <LoadingPage />;
        default:
          return <LoadingPage />;
      }
    } else {
      console.log("show mail form")
      return <EmailForm setState={prop.setState} getSubscription={getSubscription} setSessionKey={prop.setSessionKey} />
    }
  };

  return (
    <>
      <Typography
        variant="h1"
        style={{
          fontFamily: "Open Sans",
          fontSize: "50px",
          fontWeight: "800",
          padding: "20px",
        }}
      >
        <span style={{ color: "white" }}>É MUITO FÁCIL</span> PARTICIPAR!
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontFamily: "Open Sans",
          fontSize: "20px",
          padding: "20px",
          color: "white",
          fontWeight: "400",
        }}
      >
        A <b style={{ color: "black" }}>APROVEC</b> e o{" "}
        <b style={{ color: "black" }}>DriveSocial</b> se uniram para trazer mais
        segurança e momentos de lazer para os motoristas de app.
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontFamily: "Open Sans",
          fontSize: "15px",
          padding: "2px",
          fontWeight: "700",
          marginLeft: "30px",
          marginRight: "30px",
          color: "white",
          backgroundColor: "black",
        }}
      >
        Serão dois sorteios por mês realizados pela APROVEC exclusivamente para
        usuários ativos do DriveSocial.
      </Typography>
      <Typography
        variant="body2"
        style={{
          fontFamily: "Open Sans",
          fontSize: "15px",
          padding: "20px",
          color: "white",
          fontWeight: "400",
        }}
      >
        Os sorteios serão realizados ao vivo nos perfis{" "}
        <b style={{ color: "black" }}>@drivesocialbr</b> e{" "}
        <b style={{ color: "black" }}>@aprovecaevolucao</b> no Instagram.
      </Typography>
      {component()}
    </>
  );
}

export default App;
