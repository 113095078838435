import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import Config from "../Config";

function Subscribed(prop) {
  const [linkCopied, setLinkCopied] = useState(false);
  const [refferrallLink, setRefferralLink] = useState("");
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [points, setPoints] = useState(null);
  function getPoints() {
    if (points == null) {
      axios
        .get(`${Config().url}/points/${Config().promoId}/${prop.profile.uid}`)
        .then((result) => {
          setPoints(parseInt(result.data.points) + 1);
        });
    }
  }
  function getRefferralCode(prop) {
    if (refferrallLink === "") {
      axios
        .get(
          `https://server.drivesocial.io/DSAPI/api19.php?sessionkey=${prop.sessionKey}&action=getMyReferralLink&argument=none`
        )
        .then((result) => {
          setRefferralLink(result.data.link);
        });
    }
  }
  getRefferralCode(prop);
  getPoints();
  return (
    <div>
      <FormControl
        style={{
          margin: "10px",
          backgroundColor: "#F2F2F2",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Typography variant="body2" style={{ padding: "10px" }}>
          Parabéns! Você está inscrito para os sorteios da parceria Aprovec e
          DriveSocial!
        </Typography>
        <Typography
          variant="body1"
          style={{ paddingTop: "10px", textAlign: "center" }}
          aria-describedby="helper-text"
        >
          <b>{`Você tem ${points} pontos!`}</b>
        </Typography>
        <FormHelperText
          id="helper-text"
          style={{ paddingBottom: "5px", textAlign: "center", color: "red" }}
        >
          1 ponto = 1 chance no sorteio
        </FormHelperText>
        {refferrallLink !== "" && (
          <Button
            variant="contained"
            onClick={() => {
              if (showShareDialog) {
                setShowShareDialog(false);
              } else {
                setShowShareDialog(true);
              }
            }}
          >
            Indique e ganhe mais pontos!
          </Button>
        )}
        <br />
        <Button
          variant="outlined"
          onClick={() => {
            if (showRules) {
              setShowRules(false);
            } else {
              setShowRules(true);
            }
          }}
        >
          Mais informações
        </Button>
      </FormControl>
      <Dialog open={showShareDialog}>
        <DialogTitle>Indique e ganhe!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              Indique o DriveSocial para mais pessoas através do seu link de
              indicação e ganhe pontos para participar da promoção a cada novo
              usuário inscrito no DriveSocial através da sua indicação!
            </Typography>
            <br />
            <Typography variant="h5">Seu link de indicação:</Typography>
            <Typography variant="body2">
              <Link href={refferrallLink}>{refferrallLink}</Link>
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(
                  `Estou participando da promoção DriveSocial + APROVEC. Crie sua conta no DriveSocial gratuitamente através do meu link de indicação e concorra você também: ${refferrallLink}`
                );
                setLinkCopied(true)
              }}
            >
              Copiar Link
            </Button>
            {linkCopied && (
              <Typography variant="body2" style={{ color: "red" }}>
                Link copiado com sucesso!
              </Typography>
            )}
            <br />
          </DialogContentText>

          <DialogActions
            onClick={() => {
              setShowShareDialog(false);
            }}
          >
            Fechar
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog open={showRules}>
        <DialogTitle>Regras e Informações sobre pontos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              Para ativar sua participação, além de estar inscrito é necessário
              ter pontos e ter estado online no DriveSocial a menos de 24h do
              sorteio.
            </Typography>
            <br />
            <Typography variant="h5">Como obter pontos?</Typography>
            <Typography variant="body2">
              <ul>
                <li>Ao se inscrever na promoção, você já ganha 1 ponto.</li>
                <li>
                  Ao indicar o DriveSocial usando seu link de indicação você
                  ganha 1 ponto a cada nova conta criada.
                </li>
                <li>
                  1 ponto = uma chance de ganhar no sorteio. Quanto mais pontos,
                  mais chances você tem de ganhar.
                </li>
              </ul>
            </Typography>
          </DialogContentText>

          <DialogActions
            onClick={() => {
              setShowRules(false);
            }}
          >
            Fechar
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default Subscribed;
